import { ArrowLeftOutlined, CloseOutlined, PlusOutlined, RestOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Divider, Empty, Input, Modal, PageHeader, Radio, RadioChangeEvent, Row, Select, Space, Spin, Table, Tag, Typography } from 'antd';
import React, { FC, useState, useCallback, useEffect, ChangeEvent, KeyboardEvent, useRef, MutableRefObject } from 'react';
import { useHistory } from 'react-router-dom';
import MainLayout from '../../components/main-layout/main-layout';
import './bet.css';
import moment from 'moment';
import { BillType } from '../../types/bill.type';
import { BetType } from '../../types/bet.type';
import { PeriodType } from '../../types/period.type';
// @ts-ignore
import * as io from 'socket.io-client';
import { cancelBill, createBets, createBill, getBills, logout } from '../../services/services';
import { utils } from '../../services/utils';
import Notification from '../../components/notification/notification';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { ColumnsType } from 'antd/lib/table';

const { Title, Text, Paragraph } = Typography;

const setBillColumns = (onShowBetsInBill: Function, onDeleteBill: Function) => {
    const columns: ColumnsType<BillType> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return (
                    <>
                        {record.status === 'inactive' ? <><CloseOutlined style={{ color: '#ff4d4f' }} /> {index + 1}</> : index + 1}
                    </>
                );
            }
        },
        {
            title: 'เวลาแทง',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string, record: BillType, index: number) => {
                return moment(record.createdAt).format("DD-MM-YYYY HH:mm:ss");
            }
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'detail',
            key: 'detail',
            align: 'center',
            render: (text: string, record: BillType, index: number) => {
                return text ? text : '-'
            }
        },
        {
            title: 'ยอดเงิน',
            dataIndex: 'price',
            key: 'price',
            align: 'right',
            render: (text: string, record: BillType, index: number) => {
                return <span style={{ color: '#389e0d' }}>{text}</span>
            }
        },
        {
            title: 'การแทง',
            dataIndex: 'betType',
            key: 'betType',
            render: (text: string, record: BillType, index: number) => {
                return (
                    <>
                        <Space size="small" align="center">
                            <Button size="small" type="primary" onClick={() => onShowBetsInBill(record)}><SnippetsOutlined /></Button>
                            {record.status === 'active' ? <Button size="small" type="primary" danger onClick={() => onDeleteBill(record)}><RestOutlined /></Button> : <Button size="small" type="primary" disabled={true} danger><RestOutlined /></Button>}
                        </Space>
                    </>
                )
            }
        },
    ];
    return columns;
}

const betColumns: ColumnsType<any> = [
    {
        title: 'ลำดับที่',
        dataIndex: 'id',
        key: 'id',
        render: (text: string, record: BetType, index: number) => {
            return index + 1;
        }
    },
    // {
    //     title: 'หมายเลข',
    //     dataIndex: 'numbers',
    //     key: 'numbers',
    // },
    // {
    //     title: 'ประเภท',
    //     dataIndex: 'betDataMaster.name',
    //     key: 'betDataMaster.name',
    //     render: (text: string, record: BetType, index: number) => {
    //         return record.betDataMaster.name;
    //     }
    // },
    {
        title: 'ประเภท @ หมายเลข',
        dataIndex: 'numbersBetDataMaster',
        key: 'numbersBetDataMaster',
        align: 'center',
        render: (text: string, record: BetType, index: number) => {
            return record.betDataMaster.name + " @ " + record.numbers;
        }
    },
    {
        title: 'ราคา',
        dataIndex: 'price',
        key: 'price',
        align: 'right'
    },
];

const renderer = (props: CountdownRenderProps) => {
    const { hours, minutes, seconds } = props.formatted;
    // Render a countdown
    return <span style={{ fontWeight: 'bold' }}>({hours}:{minutes}:{seconds})</span>;
};

const Bet: FC = (props: any) => {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [isShowBill, setIsShowBill] = useState(false);
    const [isShowDeleteBill, setIsShowDeleteBill] = useState(false);
    const [isClosePeriod, setIsClosePeriod] = useState(false);
    const [betRole, setBetRole] = useState(0);
    const [bet, setBet] = useState<any>([]);
    const [bill, setBill] = useState([]);
    const [allBill, setAllBill] = useState(null);
    const [currentBetsInBill, setCurrentBetsInBill] = useState<any>([]);
    const [currentBill, setCurrentBill] = useState<BillType>();
    const [period, setPeriod] = useState<PeriodType>(props.location.state?.period);
    const endpoint = process.env.REACT_APP_API_URL;

    // ref
    const betNumberRef = useRef(null);
    const betUpNumberRef = useRef(null);
    const betDownNumberRef = useRef(null);
    const betToadNumberRef = useRef(null);
    const addBillRef = useRef(null);
    const backNumberRef = useRef(null);

    // socket
    let socket: any = null;

    // input
    const [betNumber, setBetNumber] = useState('');
    const [betUpPrice, setBetUpPrice] = useState('');
    const [betDownPrice, setBetDownPrice] = useState('');
    const [betToadPrice, setBetToadPrice] = useState('');
    const [note, setNote] = useState('');
    const [total, setTotal] = useState(0);
    // const [betKeyType, setBetKeyType] = useState('manual');

    const connectSocket = () => {
        socket = io.connect(endpoint);
        socket.on('endPeriod', (periodId: any) => {
            if (period.id === periodId) {
                setIsClosePeriod(true);
                // messageTimeup = 'หวยรอบนี้ปิดรับแล้ว';
                // this.setState({ betTimeupModal: true, messageTimeup });
            }
        })
    }

    const getAllBills = useCallback(async (periodId: number, memberId: string | null) => {
        const response = await getBills(periodId, memberId);
        setAllBill(response.data);
    }, []);

    const onCopyText = (evt: any) => {
        evt.preventDefault();
        const text = (evt.clipboardData).getData("text");
        
        return letBetByCopy(text);
    }

    useEffect(() => {
        if (!period) return history.push('/');
        if (period.status === 'inactive') return history.push('/');

        window.addEventListener('paste', onCopyText);
        connectSocket();

        return () => {
            window.removeEventListener('paste', onCopyText);
            socket.disconnect();
        }
    }, [period, history, onCopyText])

    useEffect(() => {
        getAllBills(period.id, localStorage.getItem('memberId')).then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, []);

    const onBetChange = (e: RadioChangeEvent, refFocus: MutableRefObject<any>) => {
        refFocus.current.focus();
        setBetNumber('');
        setBetRole(parseInt(e.target.value));
        setBet([]);
    }

    const renderBetNumber = () => {
        return bet.map((value: any, index: number) => (
            <Tag style={{ padding: 3, marginBottom: 5, fontSize: 18 }} key={index} onClick={() => removeBet(value, index)} color="#2db7f5">{value}</Tag>
        ))
    }

    const removeBet = (betNumber: any, indexBet: any) => {
        const newBet = bet.filter((value: any, index: any) => {
            if (index != indexBet) return value;
        });

        setBet(newBet);
    }

    const clearBet = () => {
        setBet([]);
    }

    const renderBetHeader = () => {
        if (betRole === 0) {
            return (
                <tr>
                    <th style={{ width: '25%' }}>หมายเลข</th>
                    <th></th>
                    <th style={{ width: '20%' }} className="text-center">บน</th>
                    <th style={{ width: '20%' }} className="text-center">ล่าง</th>
                    <th style={{ width: '20%' }} className="text-center"></th>
                </tr>
            );
        } else if (betRole === 1) {
            return (
                <tr>
                    <th style={{ width: '25%' }}>หมายเลข</th>
                    <th></th>
                    <th style={{ width: '20%' }} className="text-center">บน</th>
                    <th style={{ width: '20%' }} className="text-center">โต๊ด</th>
                    <th style={{ width: '20%' }} className="text-center"></th>
                </tr>
            );
        } else if (betRole === 2) {
            return (
                <tr>
                    <th style={{ width: '40%' }}>หมายเลข</th>
                    <th style={{ width: '40%' }} className="text-center">บน</th>
                    <th style={{ width: '20%' }} className="text-center"></th>
                </tr>
            );
        } else if (betRole === 3) {
            return (
                <tr>
                    <th style={{ width: '40%' }}>หมายเลข</th>
                    <th style={{ width: '20%' }} className="text-center">บน</th>
                    <th style={{ width: '20%' }} className="text-center">ล่าง</th>
                    <th style={{ width: '20%' }} className="text-center"></th>
                </tr>
            );
        } else if (betRole === 4) {
            return (
                <tr>
                    <th style={{ width: '40%' }}>หมายเลข</th>
                    <th style={{ width: '20%' }} className="text-center">วิ่งบน</th>
                    <th style={{ width: '20%' }} className="text-center">วิ่งล่าง</th>
                    <th style={{ width: '20%' }} className="text-center"></th>
                </tr>
            );
        }
    }

    const letBet = (e: ChangeEvent<HTMLInputElement>) => {

        let newBet = [...bet];
        let re = new RegExp('^[0-9\b]+$');
        let value = e.currentTarget.value;
        setBetNumber(value);

        if (isNaN(parseInt(value)) || !re.test(value)) {
            const thisNum = value.replace(/[^0-9\b]+$/g, '');
            setBetNumber(thisNum);
            return;
        }

        if (betRole === 0) { // 2 ตัว
            if (value.length === 2) {
                // let index = newBet.indexOf(value);
                // if (index === -1) {
                //     newBet.push(value);
                // }
                newBet.push(value);
                setBetNumber('');
            }
        } else if (betRole === 1) { // 3 ตัว
            if (value.length === 3) {
                // let index = newBet.indexOf(value);
                // if (index === -1) {
                //     newBet.push(value);
                // }
                newBet.push(value);
                setBetNumber('');
            }
        } else if (betRole === 2) { // 6 กลับ
            if (value.length === 3) {
                let permutation = utils.permute(value.split(''));
                let result = permutation.map(v => {
                    return v.join('');
                });

                let values = [...new Set<string>(result)];
                // newBet = values;
                newBet.push(...values);
                setBetNumber('');
            }
        } else if (betRole === 3) { // 19 ประตู
            if (value.length === 1) {
                let nums = [];
                for (let i = 0; i < 10; i++) {
                    let numForward = i + '' + value;
                    let numBackward = value + '' + i;
                    nums.push(numForward, numBackward);
                }
                let values = [...new Set(nums)];
                newBet.push(...values);
                // let values = [...new Set(nums)];
                // newBet = values;
                setBetNumber('');
            }
        } else if (betRole === 4) { // เลขวิ่ง
            if (value.length === 1) {
                // let index = newBet.indexOf(value);
                // if (index === -1) {
                //     newBet.push(value);
                // }
                newBet.push(value);
                setBetNumber('');
            }
        }

        setBet(newBet);
    }

    const letBetByCopy = (e: ChangeEvent<HTMLInputElement> | string) => {
        let newBet = [...bet];
        let re = new RegExp(/^\d+$/);
        let value = typeof (e) === 'string' ? e : e.currentTarget.value;

        let numerics: string[] = [];
        let valueSpl = null;
        if (value.indexOf("=") !== -1) {
            value = value.replace(/\s\s+/g, ' ');
            const match = value.trim().match(/\d{1,3}(\s|.)=/g);

            if (match) {
                valueSpl = match.map(v => {
                    v = v.replace(' ', '');
                    return v.slice(0, v.length - 1);
                });

                numerics = [...valueSpl];
            }

        } else {
            valueSpl = value.trim().match(/\d{1,3}/g);
            numerics = valueSpl ? valueSpl.filter(v => {
                if (re.test(v)) {
                    return v;
                }
            }) : [];
        }
        if (betRole === 0) { // 2 ตัว
            const numbers2 = numerics.filter(v => v.length === 2);
            newBet.push(...numbers2);
            // let newValues = [...new Set(newBet)];
            // newBet = newValues;
            setBetNumber('');
        } else if (betRole === 1) { // 3 ตัว
            const numbers3 = numerics.filter(v => v.length === 3);
            newBet.push(...numbers3);
            // let newValues = [...new Set(newBet)];
            // newBet = newValues;
            setBetNumber('');
        } else if (betRole === 2) { // 6 กลับ
            const numbers3 = numerics.filter(v => v.length === 3);
            // newBet.push(...numbers3);
            numbers3.forEach(num => {
                let permutation = utils.permute(num);
                let result = permutation.map(v => {
                    return v.join('');
                });

                
                let values = [...new Set<string>(result)];
                // newBet = values;
                newBet.push(...values);
            });

            setBetNumber('');
        }

        setBet(newBet);
    }

    const reverseString = (str: string) => {
        return str.split("").reverse().join("");
    }

    const reverseNumber = () => {
        const master2 = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99'];
        const master3 = ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"];
        let newBet = [...bet];
        let reverseNums: any = [];

        if (betRole === 1) {
            newBet.forEach((num: any) => {
                if (!master3.includes(num)) {
                    let permutation = utils.permute(num.split(''));
                    let result = permutation.map(v => {
                        return v.join('');
                    });

                    reverseNums.push(...result);
                }
            });
        } else {
            newBet.forEach((num: any) => {
                if (!master2.includes(num))
                    reverseNums.push(reverseString(num));
            });
        }

        newBet.push(...reverseNums);
        // let values = [...new Set(newBet)];
        // newBet = values;

        setBet(newBet);
    }

    const removeBillByKey = (key: number) => {
        let newBill = [...bill];
        let newTotal = total;

        let data: any = newBill[key];
        newTotal -= data.billTotal;

        newBill = newBill.filter((v: any, index: number) => index !== key);
        setBill(newBill);
        setTotal(newTotal);
    }

    const checkNumber = (e: ChangeEvent<HTMLInputElement>, betType: string) => {
        let re = new RegExp('^[0-9\b]+$');
        let value = e.currentTarget.value;

        const isNumber = re.test(value);
        if (!isNumber) {
            const thisNum = value.replace(/[^0-9\b]+$/g, '');
            if (betType === 'up') setBetUpPrice(thisNum);
            if (betType === 'down') setBetDownPrice(thisNum);
            if (betType === 'toad') setBetToadPrice(thisNum);
        }

        if (betType === 'up') setBetUpPrice(value);
        else if (betType === 'down') setBetDownPrice(value);
        else if (betType === 'toad') setBetToadPrice(value);
    }

    const addBill = (e: any) => {
        let newBill: any = [...bill];
        let newBet = [...bet];
        let newTotal = total;

        if (newBet.length > 0) {
            if (betRole === 0 || betRole === 3 || betRole === 4) { // 2 ตัว, 19 ประตู, เลขวิ่ง
                let betUp = parseInt(betUpPrice) || 0;
                let betDown = parseInt(betDownPrice) || 0;

                if (!betUp && !betDown) {
                    // this.handleWarningShow();
                    Notification('error', 'ไม่สามารถเพิ่มบิลได้ กรุณาใส่หวยที่แทง และราคาแทง');
                } else {

                    let billTotal = (bet.length * betUp) + (bet.length * betDown);

                    const transaction = {
                        bet,
                        betUp,
                        betDown,
                        billTotal,
                        betRole
                    };

                    newBill.push(transaction);
                    newTotal += billTotal;
                    // clear bet
                    setBet([]);
                    setBetNumber('');
                    setBetUpPrice('');
                    setBetDownPrice('');

                    // update bill
                    setBill(newBill);
                    setTotal(newTotal);

                    // this.setState({ bill, bet, total });

                }
            } else if (betRole === 1) { // 3 ตัว
                let betUp = parseInt(betUpPrice) || 0;
                let betToad = parseInt(betToadPrice) || 0;

                if (!betUp && !betToad) {
                    Notification('error', 'ไม่สามารถเพิ่มบิลได้ กรุณาใส่หวยที่แทง และราคาแทง');
                } else {

                    let billTotal = (bet.length * betUp) + (bet.length * betToad);

                    const transaction = {
                        bet,
                        betUp,
                        betToad,
                        billTotal,
                        betRole
                    };

                    newBill.push(transaction);
                    newTotal += billTotal;
                    // clear bet
                    setBet([]);
                    setBetNumber('');
                    setBetUpPrice('');
                    setBetToadPrice('');

                    // update bill
                    setBill(newBill);
                    setTotal(newTotal);
                }
            } else if (betRole === 2) { // 6 ตัว
                let betUp = parseInt(betUpPrice) || 0;
                let betToad = 0;

                if (!betUp) {
                    Notification('error', 'ไม่สามารถเพิ่มบิลได้ กรุณาใส่หวยที่แทง และราคาแทง');
                } else {

                    let billTotal = (bet.length * betUp);

                    const transaction = {
                        bet,
                        betUp,
                        betToad,
                        billTotal,
                        betRole
                    };

                    newBill.push(transaction);
                    newTotal += billTotal;
                    // clear bet
                    setBet([]);
                    setBetNumber('');
                    setBetUpPrice('');

                    // update bill
                    setBill(newBill);
                    setTotal(newTotal);
                }
            }
        } else {
            Notification('error', 'ไม่สามารถเพิ่มบิลได้ กรุณาใส่หวยที่แทง และราคาแทง');
        }

    }

    const nextFocus = (e: KeyboardEvent<HTMLInputElement>, nextRefFocus: MutableRefObject<any>) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            nextRefFocus.current.focus();
        }
    }

    const rendeBetBody = () => {
        if (betRole === 0) {
            return (
                <tr>
                    <td>
                        <Input id={`bet-number`} onKeyUp={(e) => nextFocus(e, backNumberRef)} ref={betNumberRef} value={betNumber} type="text" placeholder="หมายเลขที่แทง" pattern="[0-9]*" minLength={1} maxLength={2} onChange={(e) => letBet(e)} />
                    </td>
                    <td>
                        <Button type="primary" onKeyUp={(e) => nextFocus(e, betUpNumberRef)} ref={backNumberRef} danger block onClick={() => reverseNumber()}>กลับ</Button>
                    </td>
                    <td>
                        <Input id={`bet-up-price`} onKeyUp={(e) => nextFocus(e, betDownNumberRef)} ref={betUpNumberRef} value={betUpPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'up')} />
                    </td>
                    <td>
                        <Input id={`bet-down-price`} onKeyUp={(e) => nextFocus(e, addBillRef)} ref={betDownNumberRef} value={betDownPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'down')} />
                    </td>
                    <td>
                        <Button id={`add-bill`} type="primary" onKeyUp={(e) => nextFocus(e, betNumberRef)} ref={addBillRef} block onClick={(e) => addBill(e)}>เพิ่ม</Button>
                    </td>
                </tr>
            );
        } else if (betRole === 1) {
            return (
                <tr>
                    <td>
                        <Input id={`bet-number`} onKeyUp={(e) => nextFocus(e, backNumberRef)} ref={betNumberRef} value={betNumber} type="text" placeholder="หมายเลขที่แทง" minLength={1} maxLength={3} onChange={(e) => letBet(e)} />
                    </td>
                    <td>
                        <Button type="primary" onKeyUp={(e) => nextFocus(e, betUpNumberRef)} ref={backNumberRef} danger block onClick={() => reverseNumber()}>กลับ</Button>
                    </td>
                    <td>
                        <Input id={`bet-up-price`} onKeyUp={(e) => nextFocus(e, betToadNumberRef)} ref={betUpNumberRef} value={betUpPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'up')} />
                    </td>
                    <td>
                        <Input id={`bet-toad-price`} onKeyUp={(e) => nextFocus(e, addBillRef)} ref={betToadNumberRef} value={betToadPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'toad')} />
                    </td>
                    <td>
                        <Button id={`add-bill`} type="primary" onKeyUp={(e) => nextFocus(e, betNumberRef)} ref={addBillRef} block onClick={(e) => addBill(e)}>เพิ่ม</Button>
                    </td>
                </tr>
            );
        } else if (betRole === 2) {
            return (
                <tr>
                    <td>
                        <Input id={`bet-number`} onKeyUp={(e) => nextFocus(e, betUpNumberRef)} ref={betNumberRef} value={betNumber} type="text" placeholder="หมายเลขที่แทง" minLength={1} maxLength={3} onChange={(e) => letBet(e)} />
                    </td>
                    <td>
                        <Input id={`bet-up-price`} onKeyUp={(e) => nextFocus(e, addBillRef)} ref={betUpNumberRef} value={betUpPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'up')} />
                    </td>
                    <td>
                        <Button id={`add-bill`} type="primary" onKeyUp={(e) => nextFocus(e, betNumberRef)} ref={addBillRef} block onClick={(e) => addBill(e)}>เพิ่ม</Button>
                    </td>
                </tr>
            );
        } else if (betRole === 3) {
            return (
                <tr>
                    <td>
                        <Input id={`bet-number`} onKeyUp={(e) => nextFocus(e, betUpNumberRef)} ref={betNumberRef} value={betNumber} type="text" placeholder="หมายเลขที่แทง" minLength={1} maxLength={3} onChange={(e) => letBet(e)} />
                    </td>
                    <td>
                        <Input id={`bet-up-price`} onKeyUp={(e) => nextFocus(e, betDownNumberRef)} ref={betUpNumberRef} value={betUpPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'up')} />
                    </td>
                    <td>
                        <Input id={`bet-down-price`} onKeyUp={(e) => nextFocus(e, addBillRef)} ref={betDownNumberRef} value={betDownPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'down')} />
                    </td>
                    <td>
                        <Button id={`add-bill`} type="primary" onKeyUp={(e) => nextFocus(e, betNumberRef)} ref={addBillRef} block onClick={(e) => addBill(e)}>เพิ่ม</Button>
                    </td>
                </tr>
            );
        } else if (betRole === 4) {
            return (
                <tr>
                    <td>
                        <Input id={`bet-number`} onKeyUp={(e) => nextFocus(e, betUpNumberRef)} ref={betNumberRef} value={betNumber} type="text" placeholder="หมายเลขที่แทง" minLength={1} maxLength={1} onChange={(e) => letBet(e)} />
                    </td>
                    <td>
                        <Input id={`bet-up-price`} onKeyUp={(e) => nextFocus(e, betDownNumberRef)} ref={betUpNumberRef} value={betUpPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'up')} />
                    </td>
                    <td>
                        <Input id={`bet-down-price`} onKeyUp={(e) => nextFocus(e, addBillRef)} ref={betDownNumberRef} value={betDownPrice} type="text" placeholder="ราคา" onChange={(e) => checkNumber(e, 'down')} />
                    </td>
                    <td>
                        <Button id={`add-bill`} type="primary" onKeyUp={(e) => nextFocus(e, betNumberRef)} ref={addBillRef} block onClick={(e) => addBill(e)}>เพิ่ม</Button>
                    </td>
                </tr>
            );
        }
    }

    const renderAllBet = () => {


        let betType = '';
        let betBuy = '';

        if (bill.length > 0) {
            return bill.map((transaction: any, index) => {
                if (transaction.betRole === 0 || transaction.betRole === 3) {
                    betType = '2 ตัวท้าย';
                    betBuy = 'บน x ล่าง';
                } else if (transaction.betRole === 1 || transaction.betRole === 2) {
                    betType = '3 ตัวท้าย';
                    betBuy = 'บน x โต๊ด';
                } else if (transaction.betRole === 4) {
                    betType = 'เลขวิ่ง';
                    betBuy = 'วิ่งบน x วิ่งล่าง';
                }

                let betDesc = transaction.betUp + ' X ' + (transaction.betDown !== undefined ? transaction.betDown : transaction.betToad);
                return (
                    <tr key={index}>
                        <td style={{ width: '30%', padding: 0 }}>
                            <div style={{ textAlign: 'center' }}>
                                <p className="text-danger" style={{ fontSize: 16, marginTop: 6, marginBottom: 3 }}><b><u>{betType}</u></b></p>
                                <span><b>{betBuy}</b></span>
                                <br />
                                <span><b>{betDesc}</b></span>
                            </div>
                        </td>
                        <td style={{ width: '50%' }}>
                            <div style={{ padding: '10px 15px 10px 10px', margin: 5, backgroundColor: 'white' }}>
                                {transaction.bet.join(' ')}
                            </div>
                        </td>
                        <td style={{ width: '20%', padding: 0, margin: 0, textAlign: 'center' }}>
                            <Button type="primary" key={index} value={index} danger onClick={() => removeBillByKey(index)}>X</Button>
                        </td>
                    </tr>
                )
            });
        }

        return null;
    }

    const clear = () => {
        setTotal(0);

        setBet([]);
        setBetRole(0);
        setBetNumber('');
        setBetUpPrice('');
        setBetDownPrice('');
        setBetToadPrice('');
        setBill([]);
    }

    const getBet = (bill: any, newBill: any) => {
        let allData: any = [];
        bill.forEach((v: any) => {
            const betType = v.betRole;
            if (betType === 0 || betType === 3) { // 2 ตัว, 19 ประตู
                v.bet.forEach((num: any) => {
                    if (parseInt(v.betUp) !== 0) {
                        const value = {
                            betDataMaster: 1,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betUp)
                        };

                        allData.push(value);
                    }

                    if (parseInt(v.betDown) !== 0) {
                        const value = {
                            betDataMaster: 2,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betDown)
                        };

                        allData.push(value);
                    }
                });
            } else if (betType === 1) { // 3 ตัว
                v.bet.forEach((num: any) => {
                    if (parseInt(v.betUp) !== 0) {
                        const value = {
                            betDataMaster: 3,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betUp)
                        };

                        allData.push(value);
                    }

                    if (parseInt(v.betToad) !== 0) {
                        const value = {
                            betDataMaster: 4,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betToad)
                        };

                        allData.push(value);
                    }
                });
            } else if (betType === 2) { // 6 กลับ
                v.bet.forEach((num: any) => {

                    const value = {
                        betDataMaster: 3,
                        bill: newBill.id,
                        numbers: num,
                        price: parseInt(v.betUp)
                    };

                    allData.push(value);
                });
            } else if (betType === 4) {
                v.bet.forEach((num: any) => {
                    if (parseInt(v.betUp) !== 0) {
                        const value = {
                            betDataMaster: 5,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betUp)
                        };

                        allData.push(value);
                    }

                    if (parseInt(v.betDown) !== 0) {
                        const value = {
                            betDataMaster: 6,
                            bill: newBill.id,
                            numbers: num,
                            price: parseInt(v.betDown)
                        };

                        allData.push(value);
                    }
                });
            }
        });

        return allData;
    }

    const onSubmit = async (e: any) => {
        setLoading(true);

        const memberId: any = localStorage.getItem('memberId');
        const billPayload = {
            member: parseInt(memberId),
            period: period.id,
            detail: note,
            price: total
        };

        try {
            const billResponse = await createBill(billPayload);
            if (billResponse.status === 201) {
                let betsPayload = getBet(bill, billResponse.data);
                const betsResponse = await createBets({ bulk: betsPayload });
                if (betsResponse.status === 201) {
                    Notification('success', 'สร้างรายการแทงสำเร็จ');
                    setBet([]);
                    setBill([]);
                    setTotal(0);
                    setNote('');
                    getAllBills(period.id, localStorage.getItem('memberId')).then(() => setLoading(false)).catch(console.error);
                    setLoading(false);
                } else {
                    throw new Error();
                }
            }
        } catch (error) {
            setLoading(false);
            Notification('error', 'เกิดข้อผิดพลาด ' + error);
        }
    }

    const billInCurrentPeriod = () => {
        if (!allBill) return [];

        const data: BillType[] = allBill['data'];
        return data;
    }

    const onShowBetsInBill = (bill: BillType) => {
        setIsShowBill(true);
        setCurrentBill(bill);
        setCurrentBetsInBill(bill.bets);
    }

    const onShowDeleteBill = (bill: BillType) => {
        setIsShowDeleteBill(true);
        setCurrentBill(bill);
    }

    const onDeleteBill = async () => {
        setLoading(true);

        try {
            const currentDate = new Date();
            if (currentDate >= moment(period.dateClose).toDate()) {
                Notification('error', 'ยกเลิกโพยไม่สำเร็จ ไม่สามารถลบบิลได้เนื่องจากเกินเวลาที่กำหนด');
                setIsShowDeleteBill(false);
                setLoading(false);
            } else {
                if (currentBill) {
                    const response = await cancelBill(currentBill.id);
                    if (response.status === 200) {
                        Notification('success', 'ยกเลิกโพยสำเร็จ');
                        getAllBills(period.id, localStorage.getItem('memberId')).then(() => setLoading(false)).catch(console.error);
                        setIsShowDeleteBill(false);
                        setLoading(false);
                    } else {
                        throw new Error();
                    }
                }
            }
        } catch (error) {
            Notification('error', 'ยกเลิกโพยไม่สำเร็จ');
            setIsShowDeleteBill(false);
            setLoading(false);
        }
    }

    const renderPeriodDate = () => {
        if (!period) return <Spin spinning={true}></Spin>

        const createdDate = moment(period.dateAnnounce).add(543, 'year').toDate();
        const periodDate = moment(createdDate).format('DD MMMM YYYY');

        return periodDate;
    }

    // const changeBetKeyType = (value: string, refFocus: MutableRefObject<any>) => {
    //     refFocus.current.focus();
    //     setBetKeyType(value);
    // }

    const doubleNumber = () => {
        const data = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99'];
        let newBet = [...bet];
        newBet.push(...data);

        setBet(newBet);
    }

    const tripleNumber = () => {
        const data = ["000", "111", "222", "333", "444", "555", "666", "777", "888", "999"];
        let newBet = [...bet];
        newBet.push(...data);

        setBet(newBet);
    }

    const rowSetup = (record: BillType, index: number) => {
        if (record.status === 'inactive') {
            return 'inactive-bill';
        }
        return '';
    }

    return (
        <Spin spinning={false}>
            <MainLayout menu="bet">
                <PageHeader
                    style={{ padding: 0, marginBottom: 10 }}
                    title={<Button style={{ borderRadius: 20 }} type={'primary'} onClick={() => history.push("/")}><ArrowLeftOutlined /> เปลี่ยนตลาด</Button>}
                />
                <Space>
                    <Title level={3}>ทายผล <img src={process.env.REACT_APP_API_URL + period.lottoMaster.pic} style={{ width: 70, height: 50 }} alt="live lotto" /> หวย{period.lottoMaster?.name} <Text type="danger" style={{ fontSize: 18 }} strong>ปิดรับเวลา {moment(moment(period.dateClose).toDate()).format('HH:mm น.')} <Countdown renderer={renderer} date={moment(period.dateClose).toDate()} /></Text></Title>
                </Space>

                <Row gutter={[8, 8]}>
                    <Col lg={14} md={14} sm={24} xs={24}>
                        <div className="recommend-section">
                            <Title level={5}>คำแนะนำ</Title>
                            <Paragraph>
                                <ul style={{ marginLeft: 10 }}>
                                    <li>กลับ หมายถึง กลับเลข, เพิ่ม หมายถึง เพิ่มบิล</li>
                                    <li>กดปุ่ม Tab เพื่อขยับช่อง กดปุ่ม Enter เพิ่มยืนยัน</li>
                                </ul>
                            </Paragraph>
                        </div>

                        {/* <div className="rate-section">
                            <Title level={5}>หวย{period.lottoMaster?.name}</Title>
                            <Text style={{ float: 'right', top: 10, right: 10, position: 'absolute', fontSize: 16 }} strong>งวดวัน{moment().format("ddd DD/MM/YYYY")}</Text>

                            <Space>
                                <Title level={5}>อัตราจ่าย</Title>
                                <Select defaultValue={"1"} placeholder="- อัตราจ่ายเริ่มต้น -" style={{ width: 250 }}>
                                    <Select.Option value="1">70 บาท</Select.Option>
                                    <Select.Option value="2">90 บาท</Select.Option>
                                    <Select.Option value="3">95 บาท</Select.Option>
                                </Select>
                            </Space>
                        </div> */}

                        <div className="bet-section">
                            <div className={'box'}>
                                <Title level={5} style={{ margin: '2px 0' }}>แทงเร็ว <span style={{ float: 'right' }}><Text type="danger">งวดวันที่ {renderPeriodDate()}</Text></span></Title>
                                <Title level={5} style={{ margin: '5px 0' }}>หวย{period.lottoMaster?.name}</Title>
                                {/* <Select
                                    defaultValue="manual"
                                    style={{ width: 120 }}
                                    placeholder="เลือกวิธีคีย์"
                                    onChange={(e) => changeBetKeyType(e, betNumberRef)}
                                    options={[
                                        {
                                            value: 'manual',
                                            label: 'คีย์มือ',
                                        },
                                        {
                                            value: 'copy',
                                            label: 'ก็อปปี้วาง',
                                        },
                                    ]}
                                /> */}
                                <Divider className="divider-inside" />
                                <div className="box-inside">
                                    <Radio.Group defaultValue="0" buttonStyle="solid" onChange={(e) => onBetChange(e, betNumberRef)}>
                                        <Space>
                                            <Radio.Button value="0">2 ตัว</Radio.Button>
                                            <Radio.Button value="1">3 ตัว</Radio.Button>
                                            <Radio.Button value="2">6 กลับ</Radio.Button>
                                            <Radio.Button value="3">19 ประตู</Radio.Button>
                                            <Radio.Button value="4">เลขวิ่ง</Radio.Button>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                <Divider className="divider-inside" />
                                <div className="box-inside">
                                    <table style={{ minWidth: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '80%' }}>{renderBetNumber()}</td>
                                                <td style={{ width: '20%' }}>
                                                    <Button onClick={(e) => clearBet()} type="primary" danger block>ยกเลิก</Button>
                                                </td>
                                            </tr>
                                            {
                                                betRole === 0 ?
                                                    <tr>
                                                        <Button type="primary" style={{ marginTop: 10 }} onClick={() => doubleNumber()}><PlusOutlined /> เลขเบิ้ล</Button>
                                                    </tr> :
                                                    betRole === 1 ?
                                                        <tr>
                                                            <Button type="primary" style={{ marginTop: 10 }} onClick={() => tripleNumber()}><PlusOutlined /> เลขตอง</Button>
                                                        </tr> : <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="box-inside">
                                    <table style={{ minWidth: '100%' }}>
                                        <thead>
                                            {renderBetHeader()}
                                        </thead>
                                        <tbody>
                                            {rendeBetBody()}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="box-inside">
                                    <table style={{ minWidth: '100%' }}>
                                        <tbody>
                                            {renderAllBet()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="box-inside">
                                <Text>หมายเหตุ</Text><Input id="note" value={note} onPaste={(e: any) => setNote((e.clipboardData).getData("text"))} onChange={(e) => setNote(e.currentTarget.value)} />
                            </div>
                            <div className="box-inside" style={{ textAlign: 'center' }}>
                                <Title level={4}>รวม {total} บาท</Title>
                            </div>
                            <div className="box-inside" style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button onClick={() => clear()} danger>เริ่มใหม่</Button>
                                    <Button type="primary" disabled={total === 0 || note === ''} onClick={(e) => onSubmit(e)}>ยืนยัน</Button>
                                </Space>
                            </div>
                        </div>

                    </Col>
                    <Col lg={10} md={10} sm={24} xs={24}>
                        <div className="bill-section">
                            <Table pagination={{ defaultPageSize: 20 }} rowClassName={rowSetup} columns={setBillColumns(onShowBetsInBill, onShowDeleteBill)} dataSource={billInCurrentPeriod()} size="small" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}></Table>
                        </div>
                    </Col>
                </Row>

                <Modal title="ข้อมูลโพย"
                    open={isShowBill}
                    onOk={() => setIsShowBill(false)}
                    onCancel={() => setIsShowBill(false)}
                    okText="ปิด"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Descriptions>
                        <Descriptions.Item label="หมายเหตุ">{currentBill?.detail}</Descriptions.Item>
                        <Descriptions.Item label="ยอดเงิน">{currentBill?.price} บาท</Descriptions.Item>
                    </Descriptions>
                    <Table pagination={false} columns={betColumns} dataSource={[...currentBetsInBill]} size="small" locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}></Table>
                </Modal>

                <Modal title="ยกเลิกโพย"
                    open={isShowDeleteBill}
                    onOk={() => onDeleteBill()}
                    okText="ยกเลิกโพย"
                    onCancel={() => setIsShowDeleteBill(false)}
                    cancelText="ยกเลิก"
                >
                    <Title level={5}>คุณต้องการยกเลิกโพยนี้ใช่หรือไม่?</Title>
                    <Descriptions>
                        <Descriptions.Item label="หมายเหตุ">{currentBill?.detail}</Descriptions.Item>
                        <Descriptions.Item label="ยอดเงิน">{currentBill?.price} บาท</Descriptions.Item>
                    </Descriptions>
                </Modal>

                <Modal title="แจ้งเตือน"
                    maskClosable={false}
                    open={isClosePeriod}
                    onOk={() => history.push('/period')}
                    okText="ฉันเข้าใจแล้ว"
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Title level={3} type="danger">หวยรอบนี้ปิดรับแล้ว</Title>
                </Modal>
            </MainLayout>
        </Spin>
    )
}

export default Bet;