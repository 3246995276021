import { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import 'moment/locale/th';
import { getOverviewResult } from "../../services/services";
import { Descriptions, Spin, Tag, Typography } from "antd";
import { utils } from "../../services/utils";
import './report.css';

const Report: FC = () => {
    moment.locale('th');
    const search = useLocation().search;
    const [startDate] = useState(new URLSearchParams(search).get('startDate'));
    const [endDate] = useState(new URLSearchParams(search).get('endDate'));
    const [total] = useState(new URLSearchParams(search).get('total'));
    const [commission] = useState(new URLSearchParams(search).get('commission'));
    const [pay] = useState(new URLSearchParams(search).get('pay'));
    const [member] = useState(new URLSearchParams(search).get('member'));
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>(null);

    const searchOverviewResult = async () => {
        let filter = '?startDate=' + moment(startDate).format('YYYY-MM-DD 00:00:00');
        filter += '&endDate=' + moment(endDate).format('YYYY-MM-DD 23:59:59');
        filter += '&type=between';
        if (member) filter += '&member=' + member;
        const response = await getOverviewResult(filter);
        setData(response.data);
        // return response;
    }

    useEffect(() => {
        searchOverviewResult().then(() => setLoading(false)).catch(console.error);
    }, []);

    const renderData = () => {
        return data.map((v: any, index: number) => {

            const date = moment(v.createdAt).format('DD MMMM') + ' ' + (parseInt(moment(v.createdAt).format('YYYY')) + 543);
            const time = moment(v.createdAt).format('HH:mm:ss')

            return <>
                <tr key={index} style={{ textAlign: 'center', backgroundColor: (v.showResult === 'true' && v.earn > 0) ? '#ff4d4f' : 'white' }}>
                    <td>{v.lottoName}</td>
                    <td>{moment(v.dateAnnounce).format("DD MMM ") + moment(v.dateAnnounce).add(543, 'year').format(" YYYY")}</td>
                    {/* <td>{v.numbers}</td>
                    <td>{v.name}</td> */}
                    <td>{v.name} @ {v.numbers}</td>
                    <td style={{ textAlign: 'right' }}><span style={{ color: '#389e0d', fontWeight: 'bold' }}>{utils.numberWithCommas(v.price)}</span></td>
                    <td style={{ textAlign: 'right' }}><span style={{ color: '#cf1322', fontWeight: 'bold' }}>{v.showResult === 'true' ? utils.numberWithCommas(v.earn) : "0.00"}</span></td>
                    <td style={{ textAlign: 'right' }}><span style={{ color: '#d48806', fontWeight: 'bold' }}>{utils.numberWithCommas(v.commission)}</span></td>
                    <td>{v.detail}</td>
                    <td>{v.nickname}</td>
                    <td>{date + " " + time}</td>
                </tr>
            </>
        });
    }

    return (
        <div style={{ padding: '10px' }}>
            <Spin spinning={loading}>
                <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                    <Typography.Title level={5}>รายงาน</Typography.Title>
                </div>
                <div style={{ padding: '10px 10px 0 10px', margin: '0 10px 0 10px' }}>
                    <Descriptions style={{ paddingBottom: 0 }} column={2}>
                        <Descriptions.Item style={{ paddingBottom: 0 }} label={<Typography.Title level={5}>จากวันที่</Typography.Title>}><Typography.Title level={5}>{startDate ? moment(startDate).format('DD MMMM') + ' ' + (parseInt(moment(startDate).format('YYYY')) + 543) : "-"}</Typography.Title></Descriptions.Item>
                        <Descriptions.Item style={{ paddingBottom: 0 }} label={<Typography.Title level={5}>ถึงวันที่</Typography.Title>}><Typography.Title level={5}>{endDate ? moment(endDate).format('DD MMMM') + ' ' + (parseInt(moment(endDate).format('YYYY')) + 543) : "-"}</Typography.Title></Descriptions.Item>
                    </Descriptions>
                    <Descriptions >
                        <Descriptions.Item label={<Typography.Title level={5}>ยอดเงินรวม</Typography.Title>}><Typography.Text style={{ fontSize: 16 }} type="success">{total}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label={<Typography.Title level={5}>คอมมิชชั่นรวม</Typography.Title>}><Typography.Text style={{ fontSize: 16 }} type="warning">{commission}</Typography.Text></Descriptions.Item>
                        <Descriptions.Item label={<Typography.Title level={5}>ยอดจ่ายรวม</Typography.Title>}><Typography.Text style={{ fontSize: 16 }} type="danger">{pay}</Typography.Text></Descriptions.Item>
                    </Descriptions>
                </div>
                {data ? <>
                    <div style={{ padding: '0 10px 0 10px', margin: '0 10px 0 10px' }}>
                        <table className="report" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ width: '10%' }}>หวย</th>
                                    <th style={{ width: '10%' }}>งวด</th>
                                    {/* <th style={{ width: '10%' }}>เลขที่แทง</th>
                                    <th style={{ width: '10%' }}>ประเภท</th> */}
                                    <th style={{ width: '10%' }}>ประเภท @ เลขที่แทง</th>
                                    <th style={{ width: '10%' }}>ยอดซื้อ</th>
                                    <th style={{ width: '10%' }}>ยอดจ่าย</th>
                                    <th style={{ width: '10%' }}>ค่าคอมมิชชั่น</th>
                                    <th style={{ width: '10%' }}>ผู้ซื้อ</th>
                                    <th style={{ width: '10%' }}>ผู้ขาย</th>
                                    <th style={{ width: '20%' }}>เวลาบันทึก</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderData()}
                            </tbody>
                        </table>
                    </div>
                </> : <></>}
            </Spin>
        </div>
    );
}

export default Report;