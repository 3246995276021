import React, { FC } from 'react';
import moment from 'moment';
import { Col, Row, Typography } from 'antd';
import { PeriodType } from '../../types/period.type';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import styles from './lotto-card.module.css'
import { BsClockFill } from 'react-icons/bs'
import { RxLapTimer } from 'react-icons/rx'

const { Title, Text } = Typography;

type Props = {
    period: PeriodType
}


const LottoCard: FC<Props> = ({ period }) => {

    const renderer = (props: CountdownRenderProps) => {
        const { hours, minutes, seconds } = props.formatted;
        // Render a countdown
        return <span style={{ fontWeight: 'bold' }}>{hours}:{minutes}:{seconds}</span>;
    };

    const createdDate = moment(period.dateAnnounce).add(543, 'year').toDate();
    const periodDate = moment(createdDate).format('DD/MM/YY');

    const announceDate = moment(moment(period.dateAnnounce).toDate()).format('HH:mm น.');
    // const openDate = moment(moment(period.dateOpen).toDate()).format('HH:mm น.');
    const closeDate = moment(moment(period.dateClose).toDate()).format('HH:mm น.');

    const checkDate = () => {
        if (moment().toDate().getTime() > moment(period.dateClose).toDate().getTime()) return <Title level={5} type="danger" style={{ margin: '0' }}>ปิดรับแล้ว</Title>;

        return <Title level={5} style={{ margin: '0' }}><Countdown renderer={renderer} date={moment(period.dateClose).toDate()} /></Title>
    }

    return (
        <div className={styles.lottoCard}>
            <div className={styles.cardHeader}>
                <img src={process.env.REACT_APP_API_URL + period.lottoMaster?.pic} style={{ width: 100, height: 80 }} alt="line lotto" />
                <h5 className={styles.h5}>หวย{period.lottoMaster?.name}</h5>
            </div>
            <div className={styles.contentCard}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0.5rem' }}>
                    <Text>งวด {periodDate}</Text>
                    <Text>{`ผลออก ${announceDate}`}</Text>
                </div>

                <div style={{ display: 'flex', padding: '0.5rem', gap: '1rem', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BsClockFill style={{ fontSize: '2rem' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }}>
                        <Text>เวลาปิด</Text>
                        <Text style={{ textAlign: 'center', color: 'red' }}>{closeDate}</Text>
                    </div>
                </div>
            </div>
            <div className={styles.contentCard}>
                <div className={styles.time}>
                    <Title level={5} style={{ margin: '0' }}>
                        สถานะ
                    </Title>
                    <RxLapTimer style={{ fontSize: '1.2rem' }} /> {checkDate()}
                </div>
            </div>
            {/* <div className="time">
                <div className="time-content">
                    <Text strong style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>เวลาปิดรับ</Text>
                    <Text style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>{closeDate}</Text>
                </div>
                <div className="time-content">
                    <Text strong style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>เวลออกผล</Text>
                    <Text style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>{announceDate}</Text>
                </div>
            </div> */}
        </div >
        // <div className="lotto-card">
        //     <div className="header">
        //         <img src={process.env.REACT_APP_API_URL + period.lottoMaster?.pic} style={{ width: 100, height: 80, margin: 'auto' }} alt="line lotto" />
        //         <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>หวย{period.lottoMaster?.name}</Title>
        //     </div>
        //     <div className="content-card">
        //         {checkDate()}
        //         <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>งวด {periodDate}</Title>
        //     </div>
        // <div className="time">
        //     <div className="time-content">
        //         <Text strong style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>เวลาปิดรับ</Text>
        //         <Text style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>{closeDate}</Text>
        //     </div>
        //     <div className="time-content">
        //         <Text strong style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>เวลออกผล</Text>
        //         <Text style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>{announceDate}</Text>
        //     </div>
        // </div>
        // </div>
    )
}

export default LottoCard;