import { Button, Checkbox, CheckboxOptionType, DatePicker, Descriptions, Divider, Empty, Space, Spin, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MainLayout from "../../components/main-layout/main-layout";
import Notification from "../../components/notification/notification";
import { getLottoMaster, getResultByDay, logout } from "../../services/services";
import { utils } from "../../services/utils";
import { BetType } from "../../types/bet.type";
import { PeriodType } from "../../types/period.type";
import 'moment/locale/th';
import { CheckboxValueType } from "antd/lib/checkbox/Group";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const renderPeriodColumns = (onShowPeriodDetail: Function) => {
    const columns: ColumnsType<any> = [
        {
            title: <b>ตลาด</b>,
            dataIndex: 'lottoName',
            key: 'lottoName',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return text;
            }
        },
        {
            title: <b>งวดที่</b>,
            dataIndex: 'dateAnnounce',
            key: 'dateAnnounce',
            align: 'center',
        },
        {
            title: <b>ยอดซื้อ</b>,
            dataIndex: 'buy',
            key: 'buy',
            className: "price-pay",
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // console.log(record);
                // if (text === '0.00') return <Tag style={{ fontSize: 16 }} color="cyan">{text}</Tag>
                // return <Tag style={{ fontSize: 16 }} color="green">{text}</Tag>
                return <span style={{ fontSize: 16 }}>{utils.numberWithCommas((-record.buySummary) + "")}</span>
            }
        },
        {
            title: <b>ค่าคอมมิชชั่น</b>,
            dataIndex: 'commission',
            key: 'commission',
            // className: "commission",
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <Tag style={{ fontSize: 16 }} color="cyan">{text}</Tag>
                // return <Tag style={{ fontSize: 16 }} color="lime">{utils.numberWithCommas(text)}</Tag>
                return <span style={{ fontSize: 16 }}>{utils.numberWithCommas(record.commission)}</span>
            }
        },
        {
            title: <b>ถูกรางวัล</b>,
            dataIndex: 'pay',
            key: 'pay',
            className: "win",
            align: 'right',
            render: (text: string, record: any, index: number) => {
                // if (text === '0.00') return <Tag style={{ fontSize: 16 }} color="cyan">{text}</Tag>
                // if (record.period.showResult === 'false') return <Tag style={{ fontSize: 16 }} color="volcano">0.00</Tag>;
                // return <Tag style={{ fontSize: 16 }} color="volcano">{text}</Tag>
                return <span style={{ fontSize: 16 }}>{utils.numberWithCommas(record.paySummary)}</span>
            }
        },
        {
            title: <b>คงเหลือ</b>,
            dataIndex: 'net',
            key: 'net',
            // className: "net",
            align: 'right',
            render: (text: string, record: any, index: number) => {
                const sum = (parseFloat(record.paySummary) + parseFloat(record.commission)) + -(parseFloat(record.buySummary));
                const sumWithoutPay = parseFloat(record.buySummary) - parseFloat(record.commission);
                // if (record.period.showResult === 'false') return <Tag style={{ fontSize: 16 }} color="magenta">{utils.numberWithCommas(sumWithoutPay + "")}</Tag>;
                // return <Tag style={{ fontSize: 16 }} color="magenta">{utils.numberWithCommas(sum + "")}</Tag>

                return record.period.showResult === 'false' ? <span style={{ fontSize: 16, color: '#cf1322' }}>{utils.numberWithCommas(sumWithoutPay + "")}</span> : <span style={{ fontSize: 16, color: sum < 0 ? '#cf1322' : '#389e0d' }}>{utils.numberWithCommas(sum + "")}</span>;
            }
        },
        {
            title: <b>บิล</b>,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            render: (text: string, record: any, index: number) => {
                return <Button type="default" onClick={() => onShowPeriodDetail(record)}>ดูบิล</Button>
            }
        },
    ]

    return columns;
}

const Result: FC = () => {
    const history = useHistory();
    const [date, setDate] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [curentPeriod, setCurentPeriod] = useState<any>(null);
    const [dataSummary, setDataSummary] = useState<any>(null);
    const [summary, setSummary] = useState<any>(null);
    const [searchCondition, setSearchCondition] = useState('between');
    const [lottoMaster, setLottoMaster] = useState([]);
    const [lottoMasterChecked, setLottoMasterChecked] = useState<CheckboxValueType[]>([]);
    moment.locale('th');

    const getAllLottoMaster = useCallback(async () => {
        const response = await getLottoMaster();
        setLottoMaster(response.data.data);
    }, []);

    useEffect(() => {
        setLoading(true);
        getAllLottoMaster().then(() => setLoading(false)).catch((v) => {
            setLoading(false);
            logout();
            return history.push('/login');
        });
    }, [getAllLottoMaster]);

    const onShowPeriodDetail = (period: any) => {
        return history.push({
            pathname: '/result/bill',
            state: {
                period
            }
        })
    }

    const search = async () => {
        setLoading(true);
        try {
            let filter = 'filter=dateOpen||$gte||' + moment(date[0]).format('YYYY-MM-DD 00:00:00');
            filter += '&filter=dateOpen||$lte||' + moment(date[1]).format('YYYY-MM-DD 23:59:59');
            filter += '&filter=bills.member||$eq||' + localStorage.getItem('memberId')
            filter += '&filter=bills.status||$eq||active'
            if (lottoMasterChecked.length > 0)
                filter += '&filter=lottoMaster.id||$in||' + lottoMasterChecked.join(",");
            // filter += '&join=bill&join=bill.bet'
            // let filter = 'filter=periods.dateOpen||$eq||' + date;
            const response = await getResultByDay(filter);

            // filter data
            const summary = renderDataSummary(response.data.data);
            setDataSummary(response.data.data);
            setSummary(summary);
        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาดเล็กน้อย');
        }

        setSearchCondition('between');
        setLoading(false);
    }

    const searchByTime = async (status: string) => {
        let dateByStatus = {
            startDate: null || '',
            endDate: null || ''
        }

        if (status === 'today') {
            dateByStatus.startDate = moment().format('YYYY-MM-DD 00:00:00');
            dateByStatus.endDate = moment().format('YYYY-MM-DD 23:59:59');

        } else {
            const date = utils.getWeek(status);
            dateByStatus.startDate = date.startDate;
            dateByStatus.endDate = date.endDate;
        }

        setLoading(true);
        try {
            let filter = 'filter=dateOpen||$gte||' + dateByStatus.startDate
            filter += '&filter=dateOpen||$lte||' + dateByStatus.endDate;
            filter += '&filter=bills.member||$eq||' + localStorage.getItem('memberId')
            filter += '&filter=bills.status||$eq||active'
            if (lottoMasterChecked.length > 0)
            filter += '&filter=lottoMaster.id||$in||' + lottoMasterChecked.join(",");
            // filter += '&join=bill&join=bill.bet'
            // let filter = 'filter=periods.dateOpen||$eq||' + date;
            const response = await getResultByDay(filter);

            // filter data
            const summary = renderDataSummary(response.data.data);
            setDataSummary(response.data.data);
            setSummary(summary);
        } catch (error) {
            Notification('error', 'เกิดข้อผิดพลาดเล็กน้อย');
        }
        setDate([dateByStatus.startDate, dateByStatus.endDate]);
        setSearchCondition('date');
        setLoading(false);
    }

    const renderDataSummary = (dataResult?: any) => {
        let dataSummarries = dataSummary;
        if (dataResult) {
            dataSummarries = dataResult
        }

        if (!dataSummarries) {
            return [];
        }

        if (dataSummarries.length === 0) {
            return [];
        }

        const data = dataSummarries.map((period: PeriodType, index: number) => {
            const createdDate = moment(period.createdAt).add(543, 'year').toDate();
            const periodDate = moment(createdDate).format('วันที่ DD MMMM YYYY');
            const announceDate = moment(moment(period.dateAnnounce).toDate()).format('เวลา HH:mm น.');

            let bets: any = [];
            let total = 0.00;
            let pay = 0.00;
            let commission = 0.00;
            period.bills?.forEach(bill => {
                // total += bill.price;
                pay += bill.pay;
                bill.bets.map((v: BetType) => {
                    v.detail = bill.detail;
                    v.member = bill.member.nickname;
                    v.commission = (v.betDataMaster.commissionRateMaster * 1.00) * (v.price / 100.00);
                    commission += v.commission;
                    total += v.price;
                    return v;
                });
                bets.push(...bill.bets);
            });

            return {
                lottoName: period.lottoMaster.name,
                dateAnnounce: periodDate + " (" + announceDate + ")",
                buy: utils.numberWithCommas(total + ""),
                buySummary: total,
                pay: period.showResult === 'true' ? utils.numberWithCommas(pay + "") : "0.00",
                paySummary: period.showResult === 'true' ? pay : 0,
                period,
                bets,
                commission
            };
        });

        // setTotal(sum);
        return data;
    }

    const renderBet = () => {
        if (!curentPeriod) {
            return [];
        }

        return curentPeriod.bets.map((bet: any, index: number) => {
            const status = bet.status === 'lose' ? <Tag color="red">แพ้</Tag> : <Tag color="green">ชนะ</Tag>
            const earn = bet.status === 'lose' ? <Tag color="red">{bet.earn}</Tag> : <Tag color="green">{bet.earn}</Tag>

            return {
                numbersBetDataMaster: bet.betDataMaster.name + ' @ ' + bet.numbers,
                numbers: bet.numbers,
                betDataMaster: bet.betDataMaster.name,
                price: bet.price,
                status: status,
                // earn: earn,
                commission: bet.commission,
                detail: bet.detail ? bet.detail : '-',
                member: bet.member
            }
        });
    }

    const totalSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.map((v: any) => {
            sum += v.buySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const commissionSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.map((v: any) => {
            sum += v.commission;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const paySummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let sum = 0.0;
        summary.map((v: any) => {
            if (v.period.showResult === 'true')
                sum += v.paySummary;
        });

        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const netSummary = () => {
        if (!summary) {
            return 0;
        }

        if (summary.length === 0) {
            return 0;
        }

        let total = 0.00;
        let pay = 0.00;
        let commission = 0.00;
        summary.map((v: any) => {
            total += v.buySummary;
            pay += v.paySummary;
            commission += v.commission;
        });

        let sum = total - (pay + commission);
        return utils.numberWithCommas(sum + "") + " บาท"
    }

    const goReport = () => {
        const total = totalSummary();
        const commission = commissionSummary();
        const pay = paySummary();
        let query = "startDate=" + (!date ? '' : moment(date[0]).format('YYYY-MM-DD 07:00:00'));
        query += "&endDate=" + (!date ? '' : moment(date[1]).format('YYYY-MM-DD 07:00:00'));
        query += "&total=" + total;
        query += "&commission=" + commission;
        query += "&pay=" + pay;
        query += "&member=" + localStorage.getItem('memberId');
        return 'report?' + query;
    }

    const renderSummary = (dataSummary: any) => {

        if (dataSummary.length === 0) return <></>;

        // console.log(dataSummary);
        let price = 0;
        let commission = 0;
        let win = 0;
        let net = 0;

        dataSummary.forEach((v: any) => {
            price += -(v.buySummary);
            const commissionCal = v.commission
            commission += commissionCal;
            win += v.paySummary;

            net += v.buySummary - (v.commission + v.paySummary);
        });

        // const net = (win + commission) + price;

        return (
            <Table.Summary>
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={2} align="center">
                        <span style={{ fontWeight: 'bold' }}>รวม</span>
                    </Table.Summary.Cell>
                    {/* <Table.Summary.Cell index={1} align="right" className="price">
                        {utils.numberWithCommas(price + "")}
                    </Table.Summary.Cell> */}
                    <Table.Summary.Cell index={1} align="right" className="price-pay">
                        {utils.numberWithCommas(price + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                        {utils.numberWithCommas(commission + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="right" className="price">
                        {utils.numberWithCommas(win + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right" className={net > 0 ? 'win' : 'lose'}>
                        {utils.numberWithCommas(net + "")}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                    </Table.Summary.Cell>
                </Table.Summary.Row>
            </Table.Summary>
        )

    }

    const setLottoMasterOptions = () => {
        if (lottoMaster.length === 0) return lottoMaster;

        const data = lottoMaster.map(v => {
            const option: CheckboxOptionType = {
                label: v["name"],
                value: v["id"]
            }
            return option;
        });
        return data;
    }

    const onChangeLottoMaster = (e: CheckboxValueType[]) => {
        setLottoMasterChecked(e);
    }

    return (
        <MainLayout menu="result">
            <Spin spinning={loading}>
                <Title level={4}>สรุปผล</Title>
                <Space>
                    <RangePicker onChange={(e: any) => setDate(e)} placeholder={["จากวันที่", "ถึงวันที่"]} format={`DD-MM-YYYY`} />
                    <Button type="primary" disabled={!date ? true : false} onClick={() => search()}>ค้นหา</Button>
                    <Button type="primary" onClick={() => searchByTime('today')}>วันนี้</Button>
                    <Button type="primary" onClick={() => searchByTime('current-week')}>สัปดาห์นี้</Button>
                    <Button type="primary" onClick={() => searchByTime('last-week')}>สัปดาห์ที่แล้ว</Button>
                </Space>
                <Divider className="divider-inside" />
                <Space>
                    <Title level={4}>เลือกตลาด</Title>
                    <Checkbox.Group style={{ fontSize: 14 }} options={setLottoMasterOptions()} onChange={(e) => onChangeLottoMaster(e)} />
                </Space>
                <Divider className="divider-inside" />
                <Descriptions column={3}>
                    <Descriptions.Item label={<Typography.Text strong>จากวันที่</Typography.Text>}>{date ? `${moment(date[0]).format('D MMMM')} ${moment(date[0]).add(543, 'y').format("YYYY")}` : "-"}</Descriptions.Item>
                    <Descriptions.Item label={<Typography.Text strong>ถึงวันที่</Typography.Text>}>{date ? `${moment(date[1]).format('D MMMM')} ${moment(date[1]).add(543, 'y').format("YYYY")}` : "-"}</Descriptions.Item>
                </Descriptions>
                <Divider className="divider-inside" />

                <Table
                    size="small"
                    columns={renderPeriodColumns(onShowPeriodDetail)}
                    dataSource={renderDataSummary()}
                    locale={{ emptyText: <Empty description={<b>ไม่มีข้อมูล</b>} /> }}
                    pagination={false}
                    summary={(dataSummary: any) => renderSummary(dataSummary)}
                />
            </Spin>
        </MainLayout>
    );
}

export default Result;